import {
  Divider,
  CollapsibleContainer,
  SpecSheet
} from '@crowley/enterprise-react-component-library';
import axios from 'axios';
import { useEffect, useMemo, useState } from 'react';
import useSWR from 'swr';
import { ErrorMessage } from './ErrorMessage';
import { Loading } from './Loading';
import { useOktaAuth } from './OktaContext';
import { Vessel } from './VesselContext';
import {
  ArrivalDepartureStatus,
  VoyageLeg,
  VoyageLegExplorer,
  VoyageStatus
} from './VoyageLegExplorer';
import { formatDateTime, formatISODate } from '../utils/formatDateTime';
import { formatVoyageLegTime } from '../utils/formatVoyageLegTime';
import { isEmpty } from 'lodash';

interface Props {
  vesselInfo?: Vessel;
}

export interface VoyageSchedule {
  voyageSummaries: VoyageSummary[];
  /*
   * Index of current voyage summary used for
   * retrieving current voyage legs
   **/
  currentVoyage: number;
}

export interface VoyageSummary {
  ActualBerthArrivalLocal?: number;
  ActualBerthDepartureLocal?: string;
  ActualPortArrivalLocal?: number;
  ActualPortDepartureLocal?: string;
  ArrivalLocal?: number;
  ArrivalLocalRaw?: string;
  AssetNumber: number;
  Charterer: string;
  DepartureLocal?: number;
  DepartureLocalRaw?: string;
  EstimatedBerthArrivalLocal?: number;
  EstimatedBerthDepartureLocal?: number;
  EstimatedPortArrivalLocal?: number;
  EstimatedPortDepartureLocal?: number;
  IMONumber: number;
  OprType: string;
  VoyageNumber: number; // unique id for voyage
  VoyageStatus: VoyageStatus;
  /**
   * 1 - Commenced
   * 2 - Scheduled
   * 3 - Forecasted
   * 4 - Completed
   * 5 - Closed
   * 6 - Canceled
   */
  VoyageStatusOrder: number;
}

export interface Voyage {
  voyageLegs: VoyageLeg[];
  /*
   * Unique Id for voyage
   **/
  VoyageNumber: number;
  VoyageStatus: VoyageStatus;
}

export const getFullDate = (timestamp?: number | string | null): string => {
  const date =
    typeof timestamp === 'string'
      ? formatISODate(timestamp)
      : formatDateTime(timestamp);

  if (isEmpty(date.formattedDate) || isEmpty(date.formattedZone))
    return 'Not Available';
  return `${date.formattedTime} on ${date.formattedDate} (${date.formattedZone})`;
};

export function VoyageOverview({ vesselInfo }: Props) {
  const { authState } = useOktaAuth();
  const [selectedVoyageLeg, setSelectedVoyageLeg] = useState<VoyageLeg>();

  const scheduleFetcher = (url: string, token: string) =>
    axios
      .get<VoyageSchedule>(url, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then((res) => res.data);

  const voyageLegsFetcher = (url: string, token: string) =>
    axios
      .get<Voyage>(url, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then((res) => res.data);

  const assetNumber = vesselInfo?.AssetNumber;
  const accessToken = authState?.accessToken?.accessToken;

  const { data: voyageSchedule, error: voyageScheduleError } = useSWR(
    () => {
      if (assetNumber) {
        return `${process.env.NEXT_PUBLIC_API_BASE}/v1/vessels/${assetNumber}/voyages`;
      }
      return null;
    },
    (key) => {
      if (assetNumber && accessToken) {
        return scheduleFetcher(key, accessToken);
      }
      return null;
    }
  );

  const currentVoyageSummary =
    voyageSchedule?.voyageSummaries[voyageSchedule.currentVoyage];

  const currentVoyageNumber = currentVoyageSummary?.VoyageNumber;

  const { data: currentVoyage, error: voyageError } = useSWR(
    () => {
      if (assetNumber && currentVoyageNumber) {
        return `${process.env.NEXT_PUBLIC_API_BASE}/v1/vessels/${assetNumber}/voyages/${currentVoyageNumber}`;
      }
      return null;
    },
    (key) => {
      if (assetNumber && currentVoyageNumber && accessToken) {
        return voyageLegsFetcher(key, accessToken);
      }
      return null;
    }
  );

  let errorMessage = null;

  if (!vesselInfo) {
    errorMessage = 'Missing Vessel Data';
  } else if (voyageScheduleError) {
    errorMessage = 'There was a problem retrieving voyage summary';
  } else if (voyageError) {
    errorMessage = 'There was a problem retrieving voyage legs';
  } else if (voyageSchedule) {
    if (voyageSchedule.currentVoyage < 0) {
      errorMessage = 'No voyage available';
    } else if (currentVoyage && currentVoyage.voyageLegs.length === 0) {
      errorMessage = 'No voyage legs available';
    }
  }

  const currentVoyageLegIndex = useMemo(() => {
    const voyageLegs = currentVoyage?.voyageLegs || [];
    let foundIndex = voyageLegs.findIndex(
      (voyageLeg) =>
        voyageLeg.ArrivalDepartureStatus === ArrivalDepartureStatus.AR
    );

    //If there is no current port, show the next port
    if (foundIndex < 0) {
      foundIndex = voyageLegs.findIndex(
        (voyageLeg) =>
          voyageLeg.ArrivalDepartureStatus === ArrivalDepartureStatus.Estimated
      );
    }

    // if there is no current or next port, show the last port information
    if (foundIndex < 0) {
      foundIndex = voyageLegs.length;
    }
    return foundIndex;
  }, [currentVoyage]);

  useEffect(() => {
    !selectedVoyageLeg &&
      setSelectedVoyageLeg(currentVoyage?.voyageLegs[currentVoyageLegIndex]);
  }, [selectedVoyageLeg, currentVoyage?.voyageLegs, currentVoyageLegIndex]);

  if (errorMessage) {
    return (
      <div className="h-screen flex flex-col items-center justify-start">
        <ErrorMessage errorMessage={errorMessage} />
      </div>
    );
  }

  const { arrivalTime, departureTime } = formatVoyageLegTime(selectedVoyageLeg);

  return (
    <div className="flex flex-col items-center lg:flex-row lg:items-start gap-x-16 px-8 pb-8">
      {currentVoyage ? (
        <>
          <VoyageLegExplorer
            voyageLegs={currentVoyage.voyageLegs}
            setSelectedVoyageLeg={setSelectedVoyageLeg}
            selectedVoyageLeg={selectedVoyageLeg}
          />
          <div className="flex-1 w-full">
            <CollapsibleContainer heading="Voyage">
              <SpecSheet
                statBlockVariant="sideBySide"
                statBlocks={[
                  {
                    stats: [
                      {
                        key: 'Voyage Number',
                        value: currentVoyageNumber
                      },
                      {
                        key: 'Voyage Status',
                        value: currentVoyageSummary?.VoyageStatus
                      },
                      {
                        key: 'Customer',
                        value: currentVoyageSummary?.Charterer
                      },
                      {
                        key: 'Opr Type',
                        value: currentVoyageSummary?.OprType
                      }
                    ]
                  }
                ]}
              />
            </CollapsibleContainer>
            <Divider />
            <SpecSheet
              statBlockVariant="sideBySide"
              statBlocks={[
                {
                  heading: 'Schedule',
                  stats: [
                    {
                      key: 'Activity',
                      value: selectedVoyageLeg?.Activity || 'Not Available'
                    },
                    {
                      key: 'Berth',
                      value: selectedVoyageLeg?.Berth || 'Not Available'
                    },
                    {
                      key: 'Remarks Operation',
                      value:
                        selectedVoyageLeg?.RemarksOperations || 'Not Available'
                    },
                    // TODO: Add cargo detail information to the API
                    {
                      key: 'Cargo Details',
                      value: 'Data Pending'
                    },
                    {
                      key: arrivalTime.key,
                      value: getFullDate(arrivalTime.value)
                    },
                    {
                      key: departureTime.key,
                      value: getFullDate(departureTime.value)
                    }
                  ]
                }
              ]}
            />
          </div>
        </>
      ) : (
        <Loading />
      )}
    </div>
  );
}
