import axios from 'axios';
import useSWR from 'swr';
import { Routes } from './routeMapper';

export const routeFetcher = (url: string, token: string) =>
  axios
    .get<Routes>(url, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      if (error.response) {
        switch (error.response.status) {
          case 400: {
            throw new Error('Missing request information');
          }
          case 404: {
            throw new Error('No routes found');
          }
          case 503: {
            throw new Error('Request timed out');
          }
          default: {
            throw new Error('Unexpected error occurred');
          }
        }
      } else if (error.request) {
        throw new Error('Error occurred sending request');
      } else {
        throw new Error('Unexpected error occurred');
      }
    });

export const logRouteResults = (routeData: Routes) => {
  let currentRoute;
  let movementDate;
  let mostRecentTimestamp;
  for (let i = 0; i < routeData.routes.length; i++) {
    currentRoute = routeData.routes[i];
    movementDate = new Date(currentRoute.movementDate);
    mostRecentTimestamp = new Date(
      currentRoute.routePoints[
        currentRoute.routePoints.length - 1
      ].MessageTimestamp
    );
    console.log(
      'route[' + i + '] -> ',
      'port call movementDate: ' +
        movementDate +
        ' (' +
        currentRoute.movementDate +
        '), mostRecentTimestamp: ' +
        mostRecentTimestamp +
        ' (' +
        currentRoute.routePoints[currentRoute.routePoints.length - 1]
          .MessageTimestamp +
        '), finalCount: ' +
        currentRoute.routePoints.length +
        ', originalCount: ' +
        currentRoute.count +
        ', originalScannedCount: ' +
        currentRoute.scannedCount
    );
  }
};

export interface UseAisRouteProps {
  assetNumber: number | undefined;
  shouldFetchRoutes: boolean | undefined;
  accessToken: string | undefined;
}

export const useAisRoute = ({
  assetNumber,
  shouldFetchRoutes,
  accessToken
}: UseAisRouteProps) => {
  const { data: routeData, error: routeError } = useSWR(
    () => {
      if (shouldFetchRoutes && assetNumber) {
        return `${process.env.NEXT_PUBLIC_API_BASE}/v1/vessels/${assetNumber}/routes`;
      }
      return null;
    },
    (key) => {
      if (shouldFetchRoutes && assetNumber && accessToken) {
        return routeFetcher(key, accessToken);
      }
      return null;
    }
  );

  return { routeData, routeError };
};
