import {
  Button,
  IconButton,
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
  Tooltip,
  Typography,
  SpecSheet,
  ActionDropdown
} from '@crowley/enterprise-react-component-library';
import {
  MdInfoOutline,
  MdOutlineMoreHoriz,
  MdShare,
  MdOpenInNew,
  MdGpsFixed,
  MdClose
} from 'react-icons/md';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Dispatch, useContext } from 'react';
import { VesselContext } from './VesselContext';
import { formatLatLong } from '../utils/formatLatLong';
import { ErrorMessage } from './ErrorMessage';
import { DateDisplay } from './DateDisplay';
import { StackedText } from './StackedText';
import { DETAILS_PAGE_URL, HOME_PAGE_URL } from '../utils/routes';
import { StatusBadge } from './StatusBadge';
import { DATA_PENDING_TEXT } from '../utils/textCopy';
import { formatDirection } from '../utils/formatVesselInfo';
import { RouteAction, RouteActionType } from '../reducers/routeReducer';

export interface VesselSummaryProps {
  routeDispatch: Dispatch<RouteAction>;
}

export function VesselSummary({ routeDispatch }: VesselSummaryProps) {
  const { query, push } = useRouter();
  const vesselAssetNumber = query.summary;
  const { vessels } = useContext(VesselContext);
  const vesselInfo = vessels.find(
    (vessel) => vessel.AssetNumber === Number(vesselAssetNumber)
  );

  const onBackClick = () => push(HOME_PAGE_URL);

  const onShowCurrentRoute = () => {
    routeDispatch({
      type: RouteActionType.FETCH_ROUTES_AND_ERROR_TEXT,
      payload: { shouldFetchRoutes: true, routeErrorText: null }
    });
  };

  return (
    <Sidebar>
      <SidebarHeader>
        <div className="flex justify-between items-center w-full">
          <Button
            variant="tertiary"
            className="!px-3"
            onClick={onBackClick}
            data-testid="vessel-summary-back-button"
          >
            <MdClose size={20} />
          </Button>
          <Typography variant="h300" className="text-silver-90">
            Vessel Summary
          </Typography>
          <ActionDropdown
            items={[
              {
                icon: MdShare,
                label: 'Copy URL',
                onClick: () =>
                  navigator.clipboard.writeText(window.location.href)
              },
              {
                icon: MdOpenInNew,
                label: 'Open in New Window',
                onClick: () =>
                  window.open(
                    `${window.location.origin}/details/${vesselInfo?.AssetNumber}`,
                    '_blank'
                  )
              }
            ]}
          >
            <IconButton Icon={MdOutlineMoreHoriz} disabled={!vesselInfo} />
          </ActionDropdown>
        </div>
      </SidebarHeader>
      <SidebarContent className="px-8 bg-silver-5 flex-col">
        {vesselInfo ? (
          <>
            <div className="flex flex-row justify-between px-4">
              <Typography as="h3" variant="h500">
                {vesselInfo.VesselName}
              </Typography>
              <StatusBadge
                status={vesselInfo.Status}
                statusDetail={vesselInfo.StatusDetail}
              />
            </div>
            <Typography
              variant="body-small"
              className="text-silver-90 px-4 flex items-center"
            >
              <MdGpsFixed size={16} className="mr-1 text-silver-40" />
              {formatLatLong(vesselInfo.Latitude, vesselInfo.Longitude)}
            </Typography>
            <SpecSheet
              className="text-silver-90 mt-12"
              statBlocks={[
                {
                  heading: `Voyage ${
                    vesselInfo.VoyageNumber
                      ? `- ${vesselInfo?.VoyageNumber}`
                      : ''
                  }`,
                  stats: [
                    {
                      key: 'Previous Port',
                      value: (
                        <StackedText
                          title={vesselInfo.PreviousPortName}
                          subTitle={vesselInfo.PreviousPortCountry}
                        />
                      )
                    },
                    {
                      key: 'Next Port',
                      value: (
                        <StackedText
                          title={vesselInfo.NextPort}
                          subTitle={DATA_PENDING_TEXT}
                        />
                      )
                    },
                    {
                      key: (
                        <Typography
                          variant="h200"
                          className="flex items-center"
                        >
                          <span className="mr-2">ATD</span>
                          <Tooltip
                            tooltipText="Actual Time Departure"
                            placement="top"
                          >
                            <MdInfoOutline
                              size={16}
                              className="text-silver-40"
                            />
                          </Tooltip>
                        </Typography>
                      ),
                      value: <DateDisplay date={vesselInfo.PreviousPortADT} />
                    },
                    {
                      key: (
                        <Typography
                          variant="h200"
                          className="flex items-center"
                        >
                          <span className="mr-2">ETA</span>
                          <Tooltip
                            tooltipText="Estimated Time Arrival"
                            placement="top"
                          >
                            <MdInfoOutline
                              size={16}
                              className="text-silver-40"
                            />
                          </Tooltip>
                        </Typography>
                      ),
                      value: <DateDisplay date={vesselInfo.ETA} />
                    }
                  ]
                },
                {
                  heading: 'Particulars',
                  stats: [
                    {
                      key: 'Speed Over Ground',
                      value: `${vesselInfo.SpeedOverGround} Knots`
                    },
                    {
                      key: 'Heading',
                      value:
                        vesselInfo.Heading &&
                        formatDirection(vesselInfo.Heading)
                    },
                    {
                      key: 'Vessel Type',
                      value: vesselInfo.VesselType
                    },
                    {
                      key: 'Cargo',
                      value: DATA_PENDING_TEXT
                    }
                  ]
                },
                {
                  heading: 'Route',
                  stats: [
                    {
                      key: '',
                      value: (
                        <Button
                          as="a"
                          className="w-full cursor-pointer"
                          onClick={onShowCurrentRoute}
                        >
                          Show Current Route
                        </Button>
                      )
                    }
                  ]
                }
              ]}
              statBlockVariant="stacked"
            />
          </>
        ) : (
          <div className="h-full flex flex-col items-center justify-center">
            <ErrorMessage />
          </div>
        )}
      </SidebarContent>
      <SidebarFooter>
        {vesselInfo ? (
          <Link
            href={{
              pathname: `${DETAILS_PAGE_URL}/${vesselInfo.AssetNumber}`,
              query: {
                from: `${HOME_PAGE_URL}?summary=${vesselInfo.AssetNumber}`
              }
            }}
            passHref
          >
            <Button as="a" className="w-full">
              More Vessel Details
            </Button>
          </Link>
        ) : (
          <Button className="w-full" disabled>
            More Vessel Details
          </Button>
        )}
      </SidebarFooter>
    </Sidebar>
  );
}
