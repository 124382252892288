import { Icon, IconProps } from '@crowley/enterprise-react-component-library';
import { VesselStatus } from './VesselContext';

export interface StatusIconProps {
  vesselStatus: VesselStatus;
  className?: string;
  heading?: number;
}

interface Properties {
  borderColor: string;
  iconName: IconProps['iconName'];
  iconColor: string;
}

export function getIconNameFromStatus(
  vesselStatus: VesselStatus
): IconProps['iconName'] | undefined {
  switch (vesselStatus) {
    case VesselStatus.Moving:
      return 'Moving';
    case VesselStatus.Stopped:
      return 'Stopped';
    case VesselStatus.OutOfService:
      return 'OutOfService';
    case VesselStatus.NA:
      return 'OutOfService';
    default:
      return;
  }
}

export function StatusIcon({
  vesselStatus,
  className = '',
  heading
}: StatusIconProps) {
  let properties: Properties = {
    borderColor: '',
    iconName: getIconNameFromStatus(vesselStatus),
    iconColor: ''
  };

  if (vesselStatus === VesselStatus.Moving) {
    properties = {
      ...properties,
      borderColor: 'border-green-50',
      iconColor: 'text-green-80'
    };
  } else if (vesselStatus === VesselStatus.Stopped) {
    properties = {
      ...properties,
      borderColor: 'border-red-50',
      iconColor: 'text-red-90'
    };
  } else if (vesselStatus === VesselStatus.OutOfService || VesselStatus.NA) {
    properties = {
      ...properties,
      borderColor: 'border-yellow-70',
      iconColor: 'text-yellow-95'
    };
  }

  return (
    <span
      className={`border rounded-full p-2 ${properties.borderColor} ${className}`}
    >
      <Icon
        iconName={properties.iconName}
        className={`${properties.iconColor} w-4 h-4`}
        iconStyles={
          heading && vesselStatus === VesselStatus.Moving
            ? {
                transform: `rotate(${heading}deg)`
              }
            : undefined
        }
      />
    </span>
  );
}
