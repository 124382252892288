import {
  Divider,
  Icon,
  Typography
} from '@crowley/enterprise-react-component-library';
import cn from 'classnames';
import { ReactNode, useMemo } from 'react';
import { MdCheckCircle } from 'react-icons/md';

interface VoyageLegExplorerItemProps {
  children: ReactNode;
  status: ArrivalDepartureStatus;
  selected: boolean;
  selectVoyageLeg: () => void;
}

/*
 * Must be kept in sync with VoyageLeg in back-end API for /v1/vessels/:assetNumber/voyages/:voyageNumber
 **/
export interface VoyageLeg {
  ActualBerthArrivalLocal?: number;
  ActualBerthDepartureLocal?: string;
  ActualPortArrivalLocal?: number; // we will have either this, or ActualBerthArrivalLocal
  ActualPortDepartureLocal?: string; // we will have either this, or ActualBerthDepartureLocal
  Activity?: Activity;
  ArrivalDepartureStatus: ArrivalDepartureStatus;
  ArrivalLocal?: number; // veson return used with Berth, Port and ArrivalDepartureStatus to populate [actual/estimate berth/port arrival] attributes
  ArrivalLocalRaw?: string; // veson return used Berth, Port and ArrivalDepartureStatus to populate [actual/estimate berth/port arrival] attributes
  AssetNumber: number;
  Berth?: string;
  Charterer: string;
  Country?: string; //TODO: Not sure if this will be available
  DepartureLocal?: number; // veson return used Berth, Port and ArrivalDepartureStatus to populate [actual/estimate berth/port departure] attributes
  DepartureLocalRaw?: string; // veson return used Berth, Port and ArrivalDepartureStatus to populate [actual/estimate berth/port departure] attributes
  EstimatedBerthArrivalLocal?: number;
  EstimatedBerthDepartureLocal?: number;
  EstimatedPortArrivalLocal?: number;
  EstimatedPortDepartureLocal?: number;
  IMONumber: number;
  LastVoyageUpdateGMT: number; // Veson provided update
  LastVoyageUpdateGMTRaw: string; // Veson provided update
  OfficialNumber: number;
  OprType: OprType; // maybe an enum, will get confirmation from Manjusha
  Order: number; // 000->100->200-> etc.
  PK: string; // partition key
  Port: string; // non-nullable
  RemarksOperations?: string;
  SK: string; // sort key
  UpdatedAt: number; // current timestamp when updated in shipping dynamoDB (Crowley team's normalization and aggregation software generated)
  UpdatedBy: string; // i.e. veson-voyage-schedule
  VesselCode: number; // unique id for Veson(etc.)
  VoyageNumber: number; // unique id for voyage
  VoyageStatus: VoyageStatus;
  /**
   * 1 - Commenced
   * 2 - Scheduled
   * 3 - Forecasted
   * 4 - Completed
   * 5 - Closed
   * 6 - Canceled
   */
  VoyageStatusOrder: number;
}

export enum ArrivalDepartureStatus {
  SA = 'SA', // 3443-TODO: Arrival & Departure are actual
  TE = 'TE', // 3443-TODO: Arrival & Departure are actual
  AR = 'AR', // 3443-TODO: Arrival is actual & Departure is estimated
  Estimated = '..' // 3443-TODO: Arrival & Departure are estimated
}

export enum OprType {
  OVOV = 'OVOV',
  OVTO = 'OVTO',
  RELT = 'RELT',
  TCOV = 'TCOV',
  TCTO = 'TCTO'
}

// 3443-TODO: Currently missing from all records
export enum Activity {
  Commencing = 'Commencing',
  Discharging = 'Discharging',
  Fueling = 'Fueling',
  CanalTransit = 'Canal Transit',
  Loading = 'Loading',
  Other = 'Other',
  Passing = 'Passing',
  Repair = 'Repair',
  Shipyard = 'Shipyard',
  Terminating = 'Terminating',
  Waiting = 'Waiting',
  Delivering = 'Delivering',
  Redelivery = 'Redelivery'
}

export enum VoyageStatus {
  Forecast = 'Forecast',
  Scheduled = 'Scheduled',
  Commenced = 'Commenced',
  Completed = 'Completed',
  Canceled = 'Canceled',
  Closed = 'Closed'
}

const IconMap = {
  [ArrivalDepartureStatus.SA]: (
    <MdCheckCircle
      size={16}
      className="ml-[-20px] mr-11 text-green-50 bg-white z-10"
    />
  ),
  [ArrivalDepartureStatus.TE]: (
    <MdCheckCircle
      size={16}
      className="ml-[-20px] mr-11 text-green-50 bg-white z-10"
    />
  ),
  [ArrivalDepartureStatus.AR]: (
    <Icon
      iconName="VoyageUnderway"
      className="z-10 ml-[-20px] mr-11 w-4 h-4 rounded-full bg-white text-blue-80"
    />
  ),
  [ArrivalDepartureStatus.Estimated]: (
    <span className="z-10 ml-[-20px] mr-11 w-4 h-4 rounded-full bg-white border-2 border-silver-50" />
  )
};

const Li = ({
  children,
  status,
  selected,
  selectVoyageLeg
}: VoyageLegExplorerItemProps) => (
  <li
    className={cn(
      'relative',
      'm-0',
      'py-4',
      'pl-11',
      'last:pb-0',
      'flex',
      'items-center',
      'hover:cursor-pointer',
      {
        'before:bg-silver-50 before:content-[""] before:absolute before:w-[1px] before:top-0 before:left-8 before:bottom-0 first:before:top-10 last:before:h-10':
          selected === false,
        'border border-silver-30 rounded-md !py-4': selected === true
      }
    )}
    onClick={selectVoyageLeg}
  >
    {IconMap[status]}
    {children}
  </li>
);

interface VoyageLegExplorerProps {
  voyageLegs: VoyageLeg[];
  setSelectedVoyageLeg: React.Dispatch<
    React.SetStateAction<VoyageLeg | undefined>
  >;
  selectedVoyageLeg: VoyageLeg | undefined;
}

export function VoyageLegExplorer({
  voyageLegs,
  setSelectedVoyageLeg,
  selectedVoyageLeg
}: VoyageLegExplorerProps) {
  const totalLegs = voyageLegs?.length;

  const nextVoyageLegIndex = useMemo(() => {
    let foundIndex = voyageLegs.findIndex(
      (voyageLeg) =>
        voyageLeg.ArrivalDepartureStatus === ArrivalDepartureStatus.Estimated
    );
    if (foundIndex < 0) {
      foundIndex = voyageLegs.length;
    }
    return foundIndex;
  }, [voyageLegs]);

  return (
    <div className="border border-silver-30 rounded-md w-[512px] h-fit px-8 py-9">
      <Typography variant="h500" as="h5">
        Voyage Schedule
      </Typography>
      <Typography variant="h100" as="h6" className="mt-1 text-silver-90">
        Port {nextVoyageLegIndex} out of {totalLegs}
      </Typography>
      <Divider />
      <ul>
        {voyageLegs.map((voyageLeg, index) => (
          <Li
            key={index}
            status={voyageLeg.ArrivalDepartureStatus}
            selected={voyageLeg.SK === selectedVoyageLeg?.SK}
            selectVoyageLeg={() => setSelectedVoyageLeg(voyageLeg)}
          >
            <div className="flex flex-col">
              <Typography variant="body-small" className="text-silver-100">
                {voyageLeg.Port}
              </Typography>
              <Typography variant="body-small" className="text-silver-90">
                {voyageLeg.Country}
              </Typography>
            </div>
          </Li>
        ))}
      </ul>
    </div>
  );
}
