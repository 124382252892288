import React from 'react';

interface Props {
  id: string;
  checked: boolean;
  onChange: () => void;
  children: React.ReactNode;
}

export function Toggle({ id, checked, onChange, children }: Props) {
  return (
    <label
      htmlFor={id}
      className="inline-flex relative items-center cursor-pointer"
    >
      <input
        type="checkbox"
        value=""
        id={id}
        className="sr-only peer"
        checked={checked}
        onChange={onChange}
      />
      <div className="w-8 h-4 bg-gray-50 peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[4px] after:left-[0px] after:bg-white after:border-gray-50 after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-blue-80"></div>
      <span className="ml-2">{children}</span>
    </label>
  );
}
