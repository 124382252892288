const DEFAULT_PRECISION = 2;

export function formatLatLong(
  lat: number,
  long: number,
  maxPrecision = DEFAULT_PRECISION
): string {
  const absLat = Math.abs(lat);
  const absLong = Math.abs(long);
  if (absLat >= 0 && absLat <= 180 && absLong >= 0 && absLong <= 180) {
    const validatedPrecision =
      maxPrecision >= 0 && maxPrecision - Math.floor(maxPrecision) == 0
        ? maxPrecision
        : DEFAULT_PRECISION;

    return `${Math.abs(
      Math.round(lat * Math.pow(10, validatedPrecision)) /
        Math.pow(10, validatedPrecision)
    )}° ${lat >= 0 ? 'N' : 'S'}, ${Math.abs(
      Math.round(long * Math.pow(10, validatedPrecision)) /
        Math.pow(10, validatedPrecision)
    )}° ${long >= 0 ? 'E' : 'W'}`;
  }

  // TODO error message (INVALID LAT LONG)
  return '';
}
