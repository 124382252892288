import {
  ActionDropdown,
  Button,
  IconButton,
  SpecSheet,
  Typography
} from '@crowley/enterprise-react-component-library';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Dispatch } from 'react';
import {
  MdClose,
  MdGpsFixed,
  MdOpenInNew,
  MdOutlineMoreHoriz,
  MdShare
} from 'react-icons/md';
import { RouteAction, RouteActionType } from '../reducers/routeReducer';
import { formatLatLong } from '../utils/formatLatLong';
import { DETAILS_PAGE_URL, HOME_PAGE_URL } from '../utils/routes';
import { DATA_PENDING_TEXT } from '../utils/textCopy';
import { DateDisplay } from './DateDisplay';
import { StackedText } from './StackedText';
import { StatusBadge } from './StatusBadge';
import { Vessel } from './VesselContext';

export interface MobileSelectedPopupProps {
  vesselInfo: Vessel;
  routeDispatch: Dispatch<RouteAction>;
}

function MobileSelectedPopupContent({
  vesselInfo,
  routeDispatch
}: MobileSelectedPopupProps) {
  const { push } = useRouter();

  const onShowCurrentRoute = () => {
    routeDispatch({
      type: RouteActionType.FETCH_ROUTES_AND_ERROR_TEXT,
      payload: { shouldFetchRoutes: true, routeErrorText: null }
    });
  };

  return (
    <div className="p-2">
      <div className="flex justify-between items-center w-full">
        <Button
          variant="tertiary"
          className="!px-3"
          onClick={() => push(HOME_PAGE_URL)}
        >
          <MdClose size={20} />
        </Button>
        <ActionDropdown
          items={[
            {
              icon: MdShare,
              label: 'Copy URL',
              onClick: () => navigator.clipboard.writeText(window.location.href)
            },
            {
              icon: MdOpenInNew,
              label: 'Open in New Window',
              onClick: () =>
                window.open(
                  `${window.location.origin}/details/${vesselInfo?.AssetNumber}`,
                  '_blank'
                )
            }
          ]}
        >
          <IconButton Icon={MdOutlineMoreHoriz} />
        </ActionDropdown>
      </div>
      <div className="flex flex-row justify-between px-4 pt-2 items-center gap-4">
        <Typography as="h3" variant="h500">
          {vesselInfo.VesselName}
        </Typography>
        <StatusBadge
          status={vesselInfo?.Status}
          statusDetail={vesselInfo.StatusDetail}
        />
      </div>
      <Typography
        variant="body-small"
        className="text-silver-90 px-4 flex items-center"
      >
        <MdGpsFixed size={16} className="mr-1 text-silver-40" />
        {formatLatLong(vesselInfo.Latitude, vesselInfo.Longitude)}
      </Typography>

      <SpecSheet
        className="text-silver-90 my-4"
        statBlocks={[
          {
            stats: [
              {
                key: 'Previous Port',
                value: (
                  <StackedText
                    title={vesselInfo.PreviousPortName}
                    subTitle={vesselInfo.PreviousPortCountry}
                  />
                )
              },
              {
                key: 'Next Port',
                value: (
                  <StackedText
                    title={vesselInfo.NextPort}
                    subTitle={DATA_PENDING_TEXT}
                  />
                )
              },
              {
                key: (
                  <Typography variant="h200" className="flex items-center">
                    <span className="mr-2">ATD</span>
                  </Typography>
                ),
                value: <DateDisplay date={vesselInfo.PreviousPortADT} />
              },
              {
                key: (
                  <Typography variant="h200" className="flex items-center">
                    <span className="mr-2">ETA</span>
                  </Typography>
                ),
                value: <DateDisplay date={vesselInfo.ETA} />
              }
            ]
          }
        ]}
        statBlockVariant="stacked"
      />
      <Button
        as="a"
        className="w-full cursor-pointer mb-3"
        onClick={onShowCurrentRoute}
      >
        Show Current Route
      </Button>
      <Link
        href={{
          pathname: `${DETAILS_PAGE_URL}/${vesselInfo.AssetNumber}`,
          query: {
            from: `${HOME_PAGE_URL}?summary=${vesselInfo.AssetNumber}`
          }
        }}
        passHref
      >
        <Button as="a" className="w-full">
          More Vessel Details
        </Button>
      </Link>
    </div>
  );
}

export default MobileSelectedPopupContent;
