import 'mapbox-gl/dist/mapbox-gl.css';
import { ActionBar } from '../components/ActionBar';
import { MapSidebar } from '../components/MapSidebar';
import { Map } from '../components/Map';
import { useReducer, useState } from 'react';
import { useWindowWidth } from '@react-hook/window-size';
import BREAKPOINTS from '../utils/mobileBreakpoints';
import { withAuth } from '../components/withAuth';
import { routeReducer } from '../reducers/routeReducer';

export function Index() {
  const [sidebarIsOpen, setSidebarIsOpen] = useState(true);
  const width = useWindowWidth();

  const [routeState, routeDispatch] = useReducer(routeReducer, {
    shouldFetchRoutes: false,
    currentRouteData: null,
    routeErrorText: null
  });

  return (
    <>
      <ActionBar />
      <main className="flex overflow-hidden">
        {width > BREAKPOINTS.medium && (
          <MapSidebar
            sidebarIsOpen={sidebarIsOpen}
            onSidebarIconClick={() => setSidebarIsOpen((prev) => !prev)}
            routeDispatch={routeDispatch}
          />
        )}
        <Map routeState={routeState} routeDispatch={routeDispatch} />
      </main>
    </>
  );
}

export default withAuth(Index);
