import {
  Link,
  ListItem,
  Typography
} from '@crowley/enterprise-react-component-library';
import { useContext, useState } from 'react';
import NextLink from 'next/link';
import { VesselContext } from './VesselContext';
import { formatDateTime } from '../utils/formatDateTime';
import { StatusIcon } from './StatusIcon';
import { IconLegendModal } from './IconLegendModal';
import { MAP_ID } from './Map';
import { useMap } from 'react-map-gl';
import { VesselCount } from './VesselCount';
import { HOME_PAGE_URL } from '../utils/routes';

export function VesselOverview() {
  const { vessels, filteredVessels } = useContext(VesselContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const map = useMap()[MAP_ID];

  return (
    <>
      <IconLegendModal
        isModalOpen={isModalOpen}
        onModalClose={() => setIsModalOpen(false)}
      />
      <div className="flex justify-between items-center px-7 shadow-sm relative h-20">
        <div>
          <Typography variant="h300">Vessel Overview</Typography>
          <div>
            <VesselCount vessels={vessels} filteredVessels={filteredVessels} />
          </div>
        </div>
        <Link size="small" onClick={() => setIsModalOpen(true)}>
          Icon Legend
        </Link>
      </div>
      <div>
        {filteredVessels.map((vessel) => {
          const { formattedDate, formattedTime } = formatDateTime(vessel.ETA);
          const etaDisplayString =
            formattedDate && formattedTime
              ? `ETA ${formattedTime} ${formattedDate}`
              : '';

          return (
            <NextLink
              shallow
              passHref
              key={vessel.AssetNumber}
              href={{
                pathname: HOME_PAGE_URL,
                query: {
                  summary: vessel.AssetNumber
                }
              }}
            >
              <ListItem
                title={vessel.VesselName}
                subTitle={etaDisplayString}
                icon={
                  vessel.Status && (
                    <StatusIcon
                      vesselStatus={vessel.Status}
                      className="mr-4"
                      heading={vessel.Heading}
                    />
                  )
                }
                onClick={() => {
                  if (map) map.panTo([vessel.Longitude, vessel.Latitude]);
                }}
              />
            </NextLink>
          );
        })}
      </div>
    </>
  );
}
