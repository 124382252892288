import { Routes } from '../utils/routeMapper';

export enum RouteActionType {
  UPDATE_ALL = 'UPDATE_ALL',
  FETCH_ROUTES_AND_ROUTE_DATA = 'FETCH_ROUTES_AND_ROUTE_DATA',
  FETCH_ROUTES_AND_ERROR_TEXT = 'FETCH_ROUTES_AND_ERROR_TEXT'
}
export interface RouteState {
  shouldFetchRoutes?: boolean;
  currentRouteData?: Routes | null;
  routeErrorText?: string | null;
}

export interface RouteAction {
  type: RouteActionType;
  payload: RouteState;
}

export const routeReducer = (
  state: RouteState,
  { type, payload }: RouteAction
) => {
  switch (type) {
    case 'UPDATE_ALL':
      return {
        shouldFetchRoutes: payload.shouldFetchRoutes,
        currentRouteData: payload.currentRouteData,
        routeErrorText: payload.routeErrorText
      };
    case 'FETCH_ROUTES_AND_ROUTE_DATA':
      return {
        ...state,
        shouldFetchRoutes: payload.shouldFetchRoutes,
        currentRouteData: payload.currentRouteData
      };
    case 'FETCH_ROUTES_AND_ERROR_TEXT':
      return {
        ...state,
        shouldFetchRoutes: payload.shouldFetchRoutes,
        routeErrorText: payload.routeErrorText
      };
    default:
      return state;
  }
};
