import React, { ChangeEvent, Dispatch, SetStateAction, useState } from 'react';
import { MdOutlineLayers } from 'react-icons/md';
import cn from 'classnames';
import { Typography } from '@crowley/enterprise-react-component-library';
import { MapLayer, MAP_LAYERS } from './Map';
import BREAKPOINTS from '../utils/mobileBreakpoints';
import { Toggle } from './Toggle';

export interface LayerControlProps {
  mapLayerProperties: MapLayer;
  onMapLayerPropertiesChange: Dispatch<SetStateAction<MapLayer>>;
  windowWidth: number;
  showVesselNames: boolean;
  setShowVesselNames: Dispatch<SetStateAction<boolean>>;
}

const LayerControl = ({
  mapLayerProperties,
  onMapLayerPropertiesChange,
  windowWidth,
  showVesselNames,
  setShowVesselNames
}: LayerControlProps) => {
  const [layerControlVisible, setLayerControlVisible] = useState(false);

  const onShowToggleLayerControl = () => {
    setLayerControlVisible(true);
  };

  const onHideToggleLayerControl = () => {
    setLayerControlVisible(false);
  };

  const onChangeLayer = (event: ChangeEvent<HTMLInputElement>) => {
    onMapLayerPropertiesChange((prev) => {
      return {
        ...prev,
        id: event.target.id,
        name: event.target.name,
        value: event.target.value
      };
    });
  };

  const sharedClasses: Array<string> = [
    'mapboxgl-ctrl',
    'mapboxgl-ctrl-group',
    'right-2.5',
    `${windowWidth <= BREAKPOINTS.medium ? 'bottom-28' : 'bottom-20'}`,
    'absolute'
  ];

  return (
    <>
      {layerControlVisible && (
        <div
          className={cn(sharedClasses, 'p-2', 'flex', 'flex-col', 'z-20')}
          onMouseLeave={onHideToggleLayerControl}
        >
          {MAP_LAYERS.map((layer, index) => (
            <div key={index}>
              <input
                id={`layer-${index}`}
                type="radio"
                name={layer.name}
                value={layer.value}
                checked={mapLayerProperties.id === layer.id}
                onChange={onChangeLayer}
                className="align-middle p-1"
              />
              <label htmlFor={`layer-${index}`} className="align-middle p-1">
                <Typography variant="h200" className="text-silver-100">
                  {layer.name}
                </Typography>
              </label>
            </div>
          ))}
          <Toggle
            id="toggle-layer"
            checked={showVesselNames}
            onChange={() => setShowVesselNames((prevState) => !prevState)}
          >
            <Typography variant="h200" className="text-silver-100">
              Show Vessel Names
            </Typography>
          </Toggle>
        </div>
      )}
      <div
        className={cn(sharedClasses, 'z-10')}
        onMouseOver={onShowToggleLayerControl}
      >
        <button>
          <div className="flex justify-center content-center">
            <MdOutlineLayers size={20} />
          </div>
        </button>
      </div>
    </>
  );
};

export default React.memo(LayerControl);
