import React, { MouseEvent } from 'react';
import { Marker } from 'react-map-gl';
import { MdLocationPin } from 'react-icons/md';
import { Location } from './VesselMarker';
import { ClickTooltip } from './ClickTooltip';

interface Props {
  pinMarker: Location | undefined;
  variant: 'dark' | 'light';
  removePin: (e: MouseEvent) => void;
}

function PinMarker({ pinMarker, removePin, variant }: Props) {
  const Content = () => (
    <>
      <span className="font-extralight">
        {pinMarker?.latitude?.toFixed(3)}, {pinMarker?.longitude?.toFixed(3)}
      </span>
    </>
  );

  const customStyles = { width: 'max-content' };

  return (
    <Marker {...pinMarker}>
      <ClickTooltip
        tooltipContent={<Content />}
        customStyles={customStyles}
        removePin={removePin}
        variant={variant}
        placement="top"
      >
        <MdLocationPin
          color={variant === 'dark' ? '#192b51' : '#ffffff'}
          size={20}
        />
      </ClickTooltip>
    </Marker>
  );
}

export default PinMarker;
