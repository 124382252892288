import 'mapbox-gl/dist/mapbox-gl.css';
import { useRouter } from 'next/router';
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
  MouseEvent,
  useRef,
  Dispatch
} from 'react';
import cn from 'classnames';
import ReactMapGLMap, {
  AttributionControl,
  Layer,
  NavigationControl,
  Popup,
  ScaleControl,
  Source,
  useMap,
  ViewState
} from 'react-map-gl';
import VesselMarker, { Location } from '../components/VesselMarker';
import { Vessel, VesselContext } from './VesselContext';
import styles from './Map.module.css';
import AutoSizer from 'react-virtualized-auto-sizer';
import { useWindowWidth } from '@react-hook/window-size';
import BREAKPOINTS from '../utils/mobileBreakpoints';
import LayerControl from './LayerControl';
import { useLocalStorageBackedState } from '../utils/useLocalStorageBackedState';
import { addRouteToMap, cleanupPreviousRoutes } from '../utils/routeMapper';
import { useOktaAuth } from './OktaContext';
import { MapLoading } from './MapLoading';
import { MapMessage, MessageSeverity } from './MapMessage';
import {
  RouteAction,
  RouteActionType,
  RouteState
} from '../reducers/routeReducer';
import { isEmpty } from 'lodash';
import { MapLayerMouseEvent } from 'react-map-gl';
import PinMarker from './PinMarker';
import MobileSelectedPopupContent from './MobileSelectedPopupContent';
import { logRouteResults, useAisRoute } from '../utils/useAisRoute';

export const MAP_ID = 'theMap';
const MAP_LAYER_PROPS_STORAGE_KEY = 'mapLayerProperties';
const MAP_MAX_ZOOM = 19;
const POPUP_VERTICAL_OFFSET_PX = 15;
const HOVER_POPUP_DELAY_MS = 700;
const DEFAULT_ZOOM_LEVEL = 3.8;
const DEFAULT_INITIAL_VIEW_STATE: ViewState = {
  latitude: 30.332184,
  longitude: -81.655647,
  zoom: DEFAULT_ZOOM_LEVEL,
  bearing: 0,
  pitch: 0,
  padding: { top: 0, bottom: 0, left: 0, right: 0 }
};

const GEBCO_BATHYMETRY_TILES = [
  'https://www.gebco.net/data_and_products/gebco_web_services/web_map_service/mapserv?SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&FORMAT=image/png&TRANSPARENT=true&LAYERS=gebco_latest&WIDTH=256&HEIGHT=256&SRS=EPSG:3857&STYLES=&BBOX={bbox-epsg-3857}'
];
const GEBCO_FEATURE_NAME_TILES = [
  'https://gis.ngdc.noaa.gov/arcgis/services/IHO/undersea_features/MapServer/WMSServer?SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&FORMAT=image/png&TRANSPARENT=true&LAYERS=0,1,2&WIDTH=256&HEIGHT=256&SRS=EPSG:3857&STYLES=&BBOX={bbox-epsg-3857}'
];

export interface MapLayer {
  /** Index of layer for radio buttons */
  id: string;
  /** Human readable string for display */
  name: string;
  /** URL used for Mapbox mapStyle */
  value: string;
}

export const DEFAULT_LAYER: MapLayer = {
  id: 'layer-0',
  name: 'Default',
  value: 'mapbox://styles/crowley-enterprise/cl20junve000314ou7dkyv1fo'
};

const SATELLITE_LAYER: MapLayer = {
  id: 'layer-1',
  name: 'Satellite',
  value: 'mapbox://styles/crowley-enterprise/cl3cx42vx000815o6lj0pji6b'
};

/**
 * The bathymetry layer currently uses the same style as the satellite layer. In the future we may
 * want to custom style the bathymetry layer by creating a Mapbox style using the Gebco data. It would
 * be a nice simplification on the current logic as the separate Gebco sources/layers would no longer be needed.
 */
const BATHYMETRY_LAYER: MapLayer = {
  id: 'layer-2',
  name: 'Bathymetry',
  value: 'mapbox://styles/crowley-enterprise/cl3cx42vx000815o6lj0pji6b'
};

export const LIGHT_LAYER: MapLayer = {
  id: 'layer-3',
  name: 'Light',
  value: 'mapbox://styles/crowley-enterprise/cl4svqgmz003j14pr9ct3e8ia'
};

export const MAP_LAYERS: Array<MapLayer> = [
  DEFAULT_LAYER,
  SATELLITE_LAYER,
  BATHYMETRY_LAYER,
  LIGHT_LAYER
];

export interface PopupInfoState {
  message: string;
  latitude: number;
  longitude: number;
  id: number;
  vessel?: Vessel;
}

export interface MapProps {
  routeState: RouteState;
  routeDispatch: Dispatch<RouteAction>;
}

export function Map({ routeState, routeDispatch }: MapProps) {
  const [mapLayerProperties, setMapLayerProperties] =
    useLocalStorageBackedState<MapLayer>({
      key: MAP_LAYER_PROPS_STORAGE_KEY,
      initialValue: DEFAULT_LAYER,
      persist: true
    });

  // Used to fire specific map event listener to draw routes on layer change
  const previousLayerName = useRef<string>(mapLayerProperties.name);

  const [showVesselNames, setShowVesselNames] = useState(false);
  const [viewState, setViewState] = useState<ViewState>(
    DEFAULT_INITIAL_VIEW_STATE
  );
  const [hoverPopupInfo, setHoverPopupInfo] = useState<PopupInfoState>();
  const [showHoverPopup, setShowHoverPopup] = useState<boolean>(false);
  const [selectedPopupInfo, setSelectedPopupInfo] = useState<PopupInfoState>();
  const [isInitialMount, setIsInitialMount] = useState<boolean>(true);
  const map = useMap()[MAP_ID];
  const {
    query: { summary }
  } = useRouter();
  const { filteredVessels } = useContext(VesselContext);
  const windowWidth = useWindowWidth();
  const { authState } = useOktaAuth();

  const assetNumber = selectedPopupInfo?.vessel?.AssetNumber;
  const accessToken = authState?.accessToken?.accessToken;

  const { shouldFetchRoutes, currentRouteData, routeErrorText } = routeState;

  const { routeData, routeError } = useAisRoute({
    assetNumber,
    shouldFetchRoutes,
    accessToken
  });

  const [clickMarker, setClickMarker] = useState<Location>();
  const handleClick = ({ lngLat }: MapLayerMouseEvent) => {
    const { lng: longitude, lat: latitude } = lngLat;
    setClickMarker({ longitude, latitude });
  };

  const removePin = (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setClickMarker(undefined);
  };

  // Build Markers When Filtered Vessels is Updated
  const markers = useMemo(() => {
    return filteredVessels.map((vesselInfo) => {
      return (
        <VesselMarker
          key={`${vesselInfo.PK}_${vesselInfo.SK}`}
          id={vesselInfo.AssetNumber}
          status={vesselInfo.Status}
          location={{
            latitude: vesselInfo.Latitude,
            longitude: vesselInfo.Longitude
          }}
          heading={vesselInfo.Heading}
          vesselName={vesselInfo.VesselName}
          hoverPopupInfoSetter={setHoverPopupInfo}
          mapLayer={mapLayerProperties.name}
        />
      );
    });
  }, [filteredVessels, mapLayerProperties]);

  // Update the selected ship status when the query params are updated (?summary=[assetNumber])
  useEffect(() => {
    if (!!summary) {
      const selectedVessel = filteredVessels.find(
        (vessel) => vessel.AssetNumber === Number(summary)
      );
      if (selectedVessel) {
        setSelectedPopupInfo({
          message: selectedVessel.VesselName,
          latitude: selectedVessel.Latitude,
          longitude: selectedVessel.Longitude,
          id: selectedVessel.AssetNumber,
          vessel: selectedVessel
        });
        if (isInitialMount) {
          setViewState({
            ...DEFAULT_INITIAL_VIEW_STATE,
            latitude: selectedVessel.Latitude,
            longitude: selectedVessel.Longitude
          });
        }
      }
    } else {
      setSelectedPopupInfo(undefined);
    }

    cleanupPreviousRoutes(map);
    routeDispatch({
      type: RouteActionType.UPDATE_ALL,
      payload: {
        shouldFetchRoutes: false,
        currentRouteData: null,
        routeErrorText: null
      }
    });

    setIsInitialMount(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [summary]);

  // Delay the hover popup
  useEffect(() => {
    let timeout: number | undefined;
    setShowHoverPopup(false);
    if (!!hoverPopupInfo) {
      timeout = window.setTimeout(
        () => setShowHoverPopup(true),
        HOVER_POPUP_DELAY_MS
      );
    } else if (timeout !== undefined) {
      window.clearTimeout(timeout);
    }
    return () => window.clearTimeout(timeout);
  }, [hoverPopupInfo]);

  const onResize = useCallback(() => {
    if (map) {
      setTimeout(() => {
        map.resize();
      }, 0);
    }

    return null;
  }, [map]);

  const handleRouteResults = useCallback(() => {
    if (routeError) {
      cleanupPreviousRoutes(map);
      routeDispatch({
        type: RouteActionType.UPDATE_ALL,
        payload: {
          shouldFetchRoutes: false,
          currentRouteData: null,
          routeErrorText: routeError.message
        }
      });
    } else if (routeData) {
      if (routeData.routes.length === 0) {
        routeDispatch({
          type: RouteActionType.FETCH_ROUTES_AND_ERROR_TEXT,
          payload: {
            shouldFetchRoutes: false,
            routeErrorText: 'No routes found'
          }
        });
      } else {
        routeDispatch({
          type: RouteActionType.FETCH_ROUTES_AND_ROUTE_DATA,
          payload: {
            shouldFetchRoutes: false,
            currentRouteData: routeData
          }
        });
        logRouteResults(routeData);
      }
    }
  }, [map, routeData, routeError, routeDispatch]);

  useEffect(() => {
    handleRouteResults();
  }, [handleRouteResults]);

  useEffect(() => {
    if (!!currentRouteData) {
      if (mapLayerProperties.name !== previousLayerName.current) {
        previousLayerName.current = mapLayerProperties.name;
        map?.getMap().once('idle', () => {
          addRouteToMap(map, currentRouteData, mapLayerProperties.name);
        });
      } else {
        addRouteToMap(map, currentRouteData, mapLayerProperties.name);
      }
    }
  }, [map, currentRouteData, mapLayerProperties.name]);

  return (
    <div className="flex-1">
      <AutoSizer>{onResize}</AutoSizer>
      <ReactMapGLMap
        {...viewState}
        onMove={(evt) => setViewState(evt.viewState)}
        mapStyle={mapLayerProperties.value}
        mapboxAccessToken={process.env.NEXT_PUBLIC_MAPBOX_ACCESS_TOKEN}
        id={MAP_ID}
        renderWorldCopies={false}
        dragRotate={false}
        maxZoom={MAP_MAX_ZOOM}
        touchPitch={false}
        attributionControl={false}
        onClick={handleClick}
      >
        {(routeError || routeErrorText) && (
          <>
            <MapMessage
              messageText={routeErrorText ?? 'An unknown error occurred'}
              messageSeverity={MessageSeverity.ERROR}
            />
          </>
        )}

        {!routeError && !routeData && shouldFetchRoutes && (
          <MapLoading
            loadingText="Fetching routes..."
            selectedLayerName={mapLayerProperties.name}
          />
        )}

        <ScaleControl position="bottom-left" unit="nautical" />

        <AttributionControl
          compact={true}
          position="bottom-left"
          customAttribution={[
            'Imagery reproduced from the GEBCO_2021 Grid, GEBCO Compilation Group (2021) GEBCO 2021 Grid (doi:10.5285/c6612cbe-50b3-0cff-e053-6c86abc09f8f)',
            'IHO-IOC GEBCO Gazetteer of Undersea Feature Names, www.gebco.net'
          ]}
        />

        {windowWidth <= BREAKPOINTS.medium && (
          <NavigationControl showCompass={true} position="bottom-right" />
        )}

        {windowWidth > BREAKPOINTS.medium && (
          <NavigationControl showCompass={false} position="bottom-right" />
        )}

        <LayerControl
          mapLayerProperties={mapLayerProperties}
          onMapLayerPropertiesChange={setMapLayerProperties}
          windowWidth={windowWidth}
          showVesselNames={showVesselNames}
          setShowVesselNames={setShowVesselNames}
        />

        {mapLayerProperties.id === BATHYMETRY_LAYER.id && (
          <>
            <Source
              id="gebcoBathymetrySource"
              type="raster"
              tileSize={256}
              tiles={GEBCO_BATHYMETRY_TILES}
            >
              <Layer
                id="gebcoBathymetryLayer"
                type="raster"
                paint={{}}
                beforeId="tunnel-street-minor-low"
              ></Layer>
            </Source>

            <Source
              id="gebcoFeatureNamesSource"
              type="raster"
              tileSize={256}
              tiles={GEBCO_FEATURE_NAME_TILES}
            >
              <Layer
                id="gebcoFeatureNamesLayer"
                type="raster"
                paint={{}}
                beforeId="tunnel-street-minor-low"
              ></Layer>
            </Source>
          </>
        )}

        {showVesselNames &&
          filteredVessels.map((vessel) => (
            <Popup
              key={vessel.AssetNumber}
              longitude={vessel.Longitude}
              latitude={vessel.Latitude}
              anchor="bottom"
              closeButton={false}
              className={cn(styles.popup)}
              offset={POPUP_VERTICAL_OFFSET_PX}
              focusAfterOpen={false}
              closeOnClick={false}
              closeOnMove={false}
              maxWidth={`${windowWidth * 0.8}px`}
            >
              {vessel.VesselName}
            </Popup>
          ))}

        {
          // Selected Vessel Popup
          !!selectedPopupInfo && (
            <Popup
              longitude={selectedPopupInfo.longitude}
              latitude={selectedPopupInfo.latitude}
              anchor="bottom"
              closeButton={false}
              className={cn(styles.popup)}
              offset={POPUP_VERTICAL_OFFSET_PX}
              focusAfterOpen={false}
              closeOnClick={false}
              closeOnMove={false}
              maxWidth={`${windowWidth * 0.8}px`}
            >
              {windowWidth <= BREAKPOINTS.medium && selectedPopupInfo.vessel ? (
                <MobileSelectedPopupContent
                  vesselInfo={selectedPopupInfo.vessel}
                  routeDispatch={routeDispatch}
                />
              ) : (
                selectedPopupInfo.message
              )}
            </Popup>
          )
        }
        {
          // Hover Popup. Only show's after delay, and if info is not the same as the selected ship
          !!showHoverPopup &&
            !!hoverPopupInfo &&
            hoverPopupInfo.id !== selectedPopupInfo?.id && (
              <Popup
                longitude={hoverPopupInfo.longitude}
                latitude={hoverPopupInfo.latitude}
                anchor="bottom"
                closeButton={false}
                className={cn(styles.popup, styles.dark)}
                offset={POPUP_VERTICAL_OFFSET_PX}
                focusAfterOpen={false}
                closeOnClick={false}
                closeOnMove={false}
              >
                {hoverPopupInfo.message}
              </Popup>
            )
        }
        {markers}
        {!isEmpty(clickMarker) && (
          <PinMarker
            pinMarker={clickMarker}
            removePin={removePin}
            variant={
              mapLayerProperties.id === LIGHT_LAYER.id ? 'dark' : 'light'
            }
          />
        )}
      </ReactMapGLMap>
    </div>
  );
}

export default Map;
