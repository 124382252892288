import { VoyageLeg } from '../components/VoyageLegExplorer';

interface TimeInfo {
  key: string;
  value: string | number | undefined;
}

interface FormatedVoyageLegTime {
  arrivalTime: TimeInfo;
  departureTime: TimeInfo;
}

export function formatVoyageLegTime(
  voyageLeg: VoyageLeg | undefined
): FormatedVoyageLegTime {
  // First we try to get if it is actual or estimated time for both berth and port times, prioritizing actual over estimated
  const berthArrival = {
    key: voyageLeg?.ActualBerthArrivalLocal
      ? 'Actual Arrival Time'
      : 'Estimated Arrival Time',
    value:
      voyageLeg?.ActualBerthArrivalLocal ||
      voyageLeg?.EstimatedBerthArrivalLocal
  };

  const berthDeparture = {
    key: voyageLeg?.ActualBerthDepartureLocal
      ? 'Actual Departure Time'
      : 'Estimated Departure Time',
    value:
      voyageLeg?.ActualBerthDepartureLocal ||
      voyageLeg?.EstimatedBerthDepartureLocal
  };

  const portArrival = {
    key: voyageLeg?.ActualPortArrivalLocal
      ? 'Actual Arrival Time'
      : 'Estimated Arrival Time',
    value:
      voyageLeg?.ActualPortArrivalLocal || voyageLeg?.EstimatedPortArrivalLocal
  };

  const portDeparture = {
    key: voyageLeg?.ActualPortDepartureLocal
      ? 'Actual Departure Time'
      : 'Estimated Departure Time',
    value:
      voyageLeg?.ActualPortDepartureLocal ||
      voyageLeg?.EstimatedPortDepartureLocal
  };

  // Once we have or arrival or estimated, for both port and berth, we need to prioritize Berth over Port times
  // If there are no port or berth times, display DepartureLocal and ArrivalLocal times
  const arrivalTime = berthArrival.value
    ? berthArrival
    : portArrival.value
    ? portArrival
    : { key: 'Arrival time', value: voyageLeg?.ArrivalLocal };
  const departureTime = berthDeparture.value
    ? berthDeparture
    : portDeparture.value
    ? portDeparture
    : { key: 'Departure time', value: voyageLeg?.DepartureLocal };

  return { arrivalTime, departureTime };
}
