import { MapRef } from 'react-map-gl';
import { VesselStatus } from '../components/VesselContext';
import {
  Feature,
  FeatureCollection,
  Geometry,
  GeoJsonProperties
} from 'geojson';
import mapboxgl, {
  GeoJSONSource,
  LngLatBoundsLike,
  LngLatLike,
  MapLayerMouseEvent
} from 'mapbox-gl';
import { bbox, distance } from '@turf/turf';
import { formatDirection } from './formatVesselInfo';
import { getFullDate } from '../components/VoyageOverview';
import { LIGHT_LAYER } from '../components/Map';

export interface RoutePoint {
  SK: string;
  /* Degrees relative to truth north (zero degrees) */
  Heading: number;
  Latitude: number;
  Longitude: number;
  /* UTC milliseconds */
  MessageTimestamp: number;
  SpeedOverGround: number;
  Status: VesselStatus;
  StatusDetail: string;
}
export interface Route {
  previousPort: string | null;
  nextPort: string | null;
  routeType: RouteType;
  routePoints: Array<RoutePoint>;
  movementDate: number;
  count: number;
  scannedCount: number;
}

export enum RouteType {
  Current = 'Current',
  Historical = 'Historical'
}

export interface Routes {
  routes: Array<Route>;
}

export enum Source {
  HistoricalPoints = 'historical-points-source',
  HistoricalRoute = 'historical-route-source'
}

export enum Layer {
  HistoricalPoints = 'historical-points-layer',
  HistoricalRoute = 'historical-route-layer'
}

export interface FeatureCoord {
  /* current browser time */
  timestamp: string;
  /* degrees relative to true north (zero degrees) */
  heading: string;
  speedOverGround: string;
  coords: Array<number>;
}

const safeRemoveLayerAndSource = (
  map: MapRef | undefined,
  layerId: string,
  sourceId: string
) => {
  if (map?.getMap().getLayer(layerId)) {
    map.getMap().removeLayer(layerId);
  }

  if (map?.getMap().getSource(sourceId)) {
    map.getMap().removeSource(sourceId);
  }
};

const findFurthestDistance = (featureCoords: FeatureCoord[]) => {
  let maxDistance = 0;
  const origin: FeatureCoord = featureCoords[0];
  let currentDistance;
  featureCoords.forEach((featureCoord) => {
    if (
      origin.coords[0] !== featureCoord.coords[0] &&
      origin.coords[1] !== featureCoord.coords[1]
    ) {
      currentDistance = distance(origin.coords, featureCoord.coords, {
        units: 'nauticalmiles'
      });
      if (currentDistance > maxDistance) {
        maxDistance = currentDistance;
      }
    }
  });
  return maxDistance;
};

const animateRoute = (
  map: MapRef | undefined,
  featureCoords: FeatureCoord[],
  lineData: Feature<Geometry, GeoJsonProperties>
) => {
  const furthestDistance = findFurthestDistance(featureCoords);

  /* Create batches of points which have their combined
  distance between them up to 5% of the furthest
  distance for the entire route */
  let distanceIncrement = furthestDistance * 0.05;
  if (distanceIncrement < 1) {
    distanceIncrement = 1;
  }
  let currentDistance = 0;
  const lineFeatureBatches: Array<Array<FeatureCoord>> = [];
  let lineFeatures: Array<FeatureCoord> = [];
  for (let i = 1; i < featureCoords.length; i++) {
    currentDistance += distance(
      featureCoords[i - 1].coords,
      featureCoords[i].coords,
      { units: 'nauticalmiles' }
    );

    lineFeatures.push(featureCoords[i]);

    if (currentDistance >= distanceIncrement) {
      currentDistance = 0;
      lineFeatureBatches.push(lineFeatures);
      lineFeatures = [];
    }
  }

  if (lineFeatures.length > 0) {
    lineFeatureBatches.push(lineFeatures);
  }

  map?.getMap().once('idle', () => {
    let i = 0;
    const lineTimer = setInterval(() => {
      if (i < lineFeatureBatches.length) {
        if (lineData.geometry.type === 'LineString') {
          lineData.geometry.coordinates.push(
            ...lineFeatureBatches[i].map((lineFeature) => lineFeature.coords)
          );
          (
            map?.getMap().getSource(Source.HistoricalRoute) as GeoJSONSource
          )?.setData(lineData);
        }
        i++;
      } else {
        clearInterval(lineTimer);
      }
    }, 50);
  });
};

const createTooltips = (map: MapRef | undefined) => {
  const popup = new mapboxgl.Popup({
    closeButton: false,
    closeOnClick: false
  });

  map
    ?.getMap()
    .on('mouseenter', Layer.HistoricalPoints, (e: MapLayerMouseEvent) => {
      if (
        e &&
        e.features &&
        e.features.length > 0 &&
        e.features[0].geometry.type === 'Point'
      ) {
        map.getMap().getCanvas().style.cursor = 'pointer';
        const coordinates: LngLatLike =
          e.features[0].geometry.coordinates.slice() as LngLatLike;
        const featureProps = e.features[0].properties;
        const html = `Timestamp: ${featureProps?.timestamp}<br />Heading: ${featureProps?.heading}
        <br />Speed over ground: ${featureProps?.speedOverGround}`;
        popup.setLngLat(coordinates).setHTML(html).addTo(map.getMap());
      }
    });

  map?.getMap().on('mouseleave', Layer.HistoricalPoints, () => {
    map.getMap().getCanvas().style.cursor = '';
    popup.remove();
  });
};

const buildRoute = (
  map: MapRef | undefined,
  routeData: Routes | undefined,
  selectedLayerName: string
) => {
  const featureCoords: FeatureCoord[] = [];
  const currentRoute = routeData?.routes.find(
    (route) => RouteType.Current === route.routeType
  );

  if (!!currentRoute) {
    currentRoute.routePoints.forEach((routePoint) => {
      featureCoords.push({
        timestamp: getFullDate(routePoint.MessageTimestamp),
        heading: formatDirection(routePoint.Heading),
        speedOverGround: `${routePoint.SpeedOverGround.toFixed(1)} Knots`,
        coords: [routePoint.Longitude, routePoint.Latitude]
      });
    });

    const lineData: Feature<Geometry, GeoJsonProperties> = {
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'LineString',
        coordinates: [featureCoords[0].coords]
      }
    };

    map?.getMap().addSource(Source.HistoricalRoute, {
      type: 'geojson',
      data: lineData
    });

    map?.getMap().addLayer({
      id: Layer.HistoricalRoute,
      type: 'line',
      source: Source.HistoricalRoute,
      layout: {
        'line-join': 'round',
        'line-cap': 'round'
      },
      paint: {
        'line-color': `${
          selectedLayerName === LIGHT_LAYER.name ? '#000000' : '#FFFFFF'
        }`,
        'line-width': ['interpolate', ['linear'], ['zoom'], 0, 4, 10, 1]
      }
    });

    /* All points */
    const historicalPoints: Array<Feature<Geometry, GeoJsonProperties>> = [];
    featureCoords.forEach((featureCoord) => {
      historicalPoints.push({
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: featureCoord.coords
        },
        properties: {
          timestamp: featureCoord.timestamp,
          heading: featureCoord.heading,
          speedOverGround: featureCoord.speedOverGround
        }
      });
    });

    const pointData: FeatureCollection<Geometry, GeoJsonProperties> = {
      type: 'FeatureCollection',
      features: []
    };

    map?.getMap().addSource(Source.HistoricalPoints, {
      type: 'geojson',
      data: pointData
    });

    map?.getMap().addLayer({
      id: Layer.HistoricalPoints,
      source: Source.HistoricalPoints,
      type: 'symbol',
      layout: {
        'icon-image': `${
          selectedLayerName === LIGHT_LAYER.name
            ? 'black-circle'
            : 'white-circle'
        }`,
        'icon-size': 0.5
      }
    });

    pointData.features.push(...historicalPoints);
    (map?.getMap().getSource(Source.HistoricalPoints) as GeoJSONSource).setData(
      pointData
    );

    const boundingBox = bbox({
      type: 'FeatureCollection',
      features: pointData.features
    });

    const BOUNDING_BOX_PADDING = 150;
    map?.getMap().fitBounds(boundingBox as LngLatBoundsLike, {
      padding: BOUNDING_BOX_PADDING
    });

    animateRoute(map, featureCoords, lineData);

    createTooltips(map);
  }
};

export const cleanupPreviousRoutes = (map: MapRef | undefined) => {
  safeRemoveLayerAndSource(map, Layer.HistoricalRoute, Source.HistoricalRoute);
  safeRemoveLayerAndSource(
    map,
    Layer.HistoricalPoints,
    Source.HistoricalPoints
  );
};

export const addRouteToMap = (
  map: MapRef | undefined,
  routeData: Routes | undefined,
  selectedLayerName: string
) => {
  if (map && map.getMap() && map.getMap().getStyle() && routeData) {
    cleanupPreviousRoutes(map);
    buildRoute(map, routeData, selectedLayerName);
  }
};
