import React, {
  ReactElement,
  MouseEvent,
  ReactNode,
  CSSProperties
} from 'react';
import { Manager, Reference, Popper } from 'react-popper';
import type { Placement } from '@popperjs/core';
import cn from 'classnames';
import { Typography } from '@crowley/enterprise-react-component-library';
import { MdClose } from 'react-icons/md';

export interface TooltipProps {
  children: ReactElement;
  placement?: Placement;
  variant?: 'dark' | 'light';
  tooltipContent: string | ReactNode;
  removePin: (e: MouseEvent) => void;
  customStyles?: CSSProperties;
}

export function ClickTooltip({
  children,
  placement = 'top',
  variant = 'dark',
  tooltipContent,
  removePin,
  customStyles
}: TooltipProps) {
  return (
    <Manager>
      <Reference>{({ ref }) => <span ref={ref}>{children}</span>}</Reference>
      <Popper
        placement={placement}
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [0, 16]
            }
          },
          {
            name: 'arrow',
            options: {
              padding: 5
            }
          }
        ]}
      >
        {({ ref, style, placement, arrowProps }) => (
          <div
            ref={ref}
            style={{ ...style, ...customStyles }}
            className={cn('px-2', 'py-1', 'rounded', 'z-10', {
              'bg-[#192b51] text-white': variant === 'dark',
              'bg-white text-black drop-shadow': variant === 'light'
            })}
          >
            <MdClose
              color={variant === 'dark' ? 'white' : 'black'}
              size={20}
              className={cn(
                'cursor-pointer',
                'absolute',
                '-top-[20px]',
                '-right-[20px]',
                'rounded-xl',
                'hover:opacity-50',
                {
                  'bg-[#192b51]': variant === 'dark',
                  'bg-white': variant === 'light'
                }
              )}
              onClick={removePin}
              data-testid="close-button"
            />
            <Typography variant="h100">{tooltipContent}</Typography>
            <div
              {...arrowProps}
              data-placement={placement}
              className={cn(
                'border-[transparent]',
                'absolute',
                'before:border-[9px]',
                'before:border-solid',
                'before:border-[transparent]',
                'before:absolute',
                'before:-z-10',
                'before:content-[""]',
                {
                  // Top Placement
                  'bottom-0 !left-[-9px]': placement.startsWith('top'),
                  'before:border-t-[#192b51]':
                    placement.startsWith('top') && variant === 'dark',
                  'before:border-t-white':
                    placement.startsWith('top') && variant === 'light',

                  // Bottom Placement
                  '-top-4 !left-[-9px]': placement.startsWith('bottom'),
                  'before:border-b-[#192b51]':
                    placement.startsWith('bottom') && variant === 'dark',
                  'before:border-b-white':
                    placement.startsWith('bottom') && variant === 'light',

                  // Left Placement
                  'right-0 !top-[-0.5rem]': placement.startsWith('left'),
                  'before:border-l-[#192b51]':
                    placement.startsWith('left') && variant === 'dark',
                  'before:border-l-white':
                    placement.startsWith('left') && variant === 'light',

                  // Right Placement
                  'left-[-1rem] !top-[-0.5rem]': placement.startsWith('right'),
                  'before:border-r-[#192b51]':
                    placement.startsWith('right') && variant === 'dark',
                  'before:drop-shadow before:border-r-white':
                    placement.startsWith('right') && variant === 'light'
                }
              )}
            />
          </div>
        )}
      </Popper>
    </Manager>
  );
}
